import Header from "./modules/Header"
import AnchorScroll from "./modules/AnchorScroll"

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('common');
    Header()
    AnchorScroll()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
