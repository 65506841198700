import ScrollSlider from "./modules/ScrollSlider"
import SectorData from "./modules/SectorData"
import VideoLayer from "./modules/VideoLayer"

export default {
  init() {
    console.log('home');
    //layer color home
    // document.querySelector('#video-container').style.setProperty("--x", "#ffffff", "");
    VideoLayer()
    ScrollSlider()
    SectorData()
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
