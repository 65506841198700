export default {
    init() {
        // scripts here run on the DOM load event
        console.log('contact page');
        const uploadInput = document.querySelector('#upload')
        const uploadBox = document.querySelector('#fakeupload')
        const requiredFields = document.querySelectorAll('[required]')
        const submitBtn = document.querySelector('.submit input')
        let validation = 0
        let checkBox = false
        const queryString = window.location.search

        if (queryString !== '') {
            document.getElementById('contact-form').scrollIntoView()
        }

        const getFileName = () => {
            const fullFileNamePath = uploadInput.value;
            const name = fullFileNamePath.split("\\")
            const finalName = name.slice(-1).pop()
            return finalName
        }
    
        uploadInput.addEventListener('change', () => {
            uploadBox.setAttribute('placeholder', getFileName())
        })

        // document.getElementById('accept-checkbox').addEventListener('click', (e) => checkBox = !checkBox)

        requiredFields.forEach(field => {
            field.addEventListener('change', () => {
                console.log(field);
                checkBox = document.getElementById('accept-checkbox').checked
                console.log(checkBox);
                if ((field.value.length == 0) && validation > 0) {
                    validation--
                } else {
                    if (field.getAttribute('type') !== "checkbox") {
                        validation++
                    }
                    
                }
                if (validation > 5 && checkBox) {
                    submitBtn.classList.remove('disable')
                } else {
                    submitBtn.classList.add('disable')
                }
                console.log(validation);
            })
        })
        
    },
    finalize() {
        // scripts here fire after init() runs
    }
};