import { isHome } from "../helpers"

export default function AnchorScroll() {
    document.querySelectorAll('.anchor').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            if (isHome()) {
                e.preventDefault()
            }
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            })
            setTimeout(() => {
                location.hash = this.getAttribute('href')
            }, 300);
            
        })
    })
}