import { isHome, isInViewport, isTop } from '../helpers'

export default function Header() {
    let lastScrollTop = 0
    const header = document.querySelector('#header-container')
    const intro = document.querySelector('.apraise__container--intro')
    const wrapperCta = document.querySelector('.wrapper-cta')
    const backgroundColor = '#FFF7EE'
    const logo = document.querySelector('.apraise__header--left a img')
    //mobile
    const menuLayerMobile = document.querySelector('.layer-menu')
    const openMenu = document.querySelector('.wrapper-mobile button')
    const imageOpen = openMenu.querySelector('img')
    const closeMenu = document.querySelector('.layer-menu__header button')
    const ctaMobile = [... document.querySelectorAll('.layer-menu__content a')]

    openMenu.addEventListener('click', (e) => {
        e.preventDefault()

        menuLayerMobile.classList.add('open')
        // document.body.style.overflowY = 'hidden'
    })
    closeMenu.addEventListener('click', (e) => {
        e.preventDefault()

        menuLayerMobile.classList.remove('open')
        // document.body.style.overflowY = 'scroll'
    })

    ctaMobile.map(cta => {
        if ((cta.getAttribute('href').includes(window.location.pathname) && window.location.pathname !== '/' && window.location.pathname !== '') 
            || (window.location.hash !== '' && cta.getAttribute('href').includes(window.location.hash))) {
            cta.querySelector('span').classList.add('selected')
        }
        cta.addEventListener('click', (e) => {
            const active = document.querySelector('.selected');
            if (active){
                active.classList.remove('selected');
            }
            e.target.classList.add('selected')
            if (isHome() && cta.classList.contains('anchor')) {
                e.preventDefault()
                menuLayerMobile.classList.remove('open')
            }
        })
    })

    if (window.location.hash) {
        header.style.top = '-140px'
    }

    window.addEventListener('scroll', (event) => {
        const st = window.pageYOffset || document.documentElement.scrollTop

        if ((st > lastScrollTop) && (lastScrollTop > 0)) {
            header.style.top = '-140px'
        } else {
            header.style.top = '0px'
            if (isHome()) {
                if (!isInViewport(intro)) {
                    header.style.backgroundColor = backgroundColor
                    wrapperCta.classList.add('dark')
                    logo.src = '/wp-content/themes/apraise/assets/icons/Logo-dark.png'
                    imageOpen.src = '/wp-content/themes/apraise/assets/icons/hamburger-menu-dark.svg'
                } else {
                    header.style.backgroundColor = 'unset'
                    wrapperCta.classList.remove('dark')
                    logo.src = '/wp-content/themes/apraise/assets/icons/Logo.png'
                    imageOpen.src = '/wp-content/themes/apraise/assets/icons/hamburger-menu-light.svg'
                }
            } else {
                header.style.backgroundColor = backgroundColor
            }
        }
        lastScrollTop = st
    })
        
}