export default function ScrollSlider() {
    const arrowButtons = [
        document.querySelector('.left-button'), 
        document.querySelector('.right-button')
    ]
    const rowContainer = document.querySelector('.scrollable')

    arrowButtons.forEach(el => {
        let scrollAdvance = el.classList.contains('left-button') ? -500 : +500
        el.addEventListener('click', (e) => {
            e.preventDefault()
            rowContainer.scroll({
                left: rowContainer.scrollLeft + scrollAdvance,
                behavior: 'smooth'
            });
        })
    })
}