import FetchDataDetail from "./modules/FetchDataDetail"

export default {
    init() {
        // scripts here run on the DOM load event
        console.log('Persone page');
        FetchDataDetail()
    },
    finalize() {
        // scripts here fire after init() runs
    }
};