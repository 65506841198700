import { isValidUrl } from "../helpers"

export default function FetchDataDetail() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const pictures = [... document.querySelectorAll("[data-js]")]
    const layer = document.querySelector('.detail-layer')
    const layerContent = vw >= 768 ? [... document.querySelectorAll('.detail-layer__content')][0] : [... document.querySelectorAll('.detail-layer__content')][1]
    const closeLayer = layerContent.querySelector('.detail-layer__content--close a')
    const cover = layerContent.querySelector('.picture img')
    const linkedinContainer = layerContent.querySelector('.external-link')
    const url = layerContent.querySelector('.external-link a')
    const title = layerContent.querySelector('.person-title')
    const subtitle = layerContent.querySelector('.person-subtitle')
    const description = layerContent.querySelector('.description')
    pictures.map(pic => {
        pic.addEventListener('click', (e) => {
            e.preventDefault()
            
            if (layerContent.classList.contains('mobile')) {
                layerContent.classList.add('in-mobile')
            }
            let personID = pic.getAttribute('data-js')
            const personName = document.querySelector(`[name="${personID}--name"]`).value
            const personCover = document.querySelector(`[name="${personID}--cover"]`).value
            const personRole = document.querySelector(`[name="${personID}--role"]`).value
            const personDescription = document.querySelector(`[name="${personID}--description"]`).value
            const personUrl = document.querySelector(`[name="${personID}--url"]`).value
            // console.log(personUrl)
            cover.src = personCover
            if (isValidUrl(personUrl)) {
                linkedinContainer.style.display = 'flex'
                url.setAttribute('href', personUrl)
            } else {
                linkedinContainer.style.display = 'none'
            }
            
            title.textContent = personName
            subtitle.textContent = personRole
            description.innerHTML = personDescription
            // console.log(personName)
            // layer.style.display = 'block'
            document.body.style.overflowY = 'hidden'
            setTimeout(() => {
                layer.classList.add('in')
            }, 300);
        })
    })
    closeLayer.addEventListener('click', (e) => {
        e.preventDefault()
        let delay = 0
        if (layerContent.classList.contains('mobile')) {
            layerContent.classList.remove('in-mobile')
            delay = 300
        }
        setTimeout(() => {
            layer.classList.remove('in')
        }, delay);
        
        // layer.style.display = 'none'
        document.body.style.overflowY = 'scroll'
    })
}