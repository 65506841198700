export default function VideoLayer() {
    const videoLayer = document.querySelector('#video-container')
    
    if (videoLayer) {
        const color = videoLayer.getAttribute("data-color")
        const opacity = videoLayer.classList.contains("layer") ? 0.45 : 0
        videoLayer.style.setProperty("--color", color, "")
        videoLayer.style.setProperty("--opacity", opacity, "")
    }
    
}